import React,{useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { graphql, useStaticQuery } from 'gatsby';
import { getBrText } from "../../helpers/helper.rendering";

const Commitment = () => {
    useEffect(() => {
        AOS.init({ duration : 2000});
    }, [])

    const { realEstate } = useStaticQuery(graphql`
     query {
         realEstate {
             sections{
                 about{
                    commitment{
                        title
                        paragraph
                    }
                 }

             }
         }
     }`)

    return realEstate.sections.about.commitment.length > 0 && (
        <section className="about-compromiso">
            <div className="container-fluid">
                <div className="row items">
                    <div data-aos="fade-left" className=" col-12 d-lg-flex align-items-center px-lg-0 px-3 mb-lg-0">
                        <h1 className="mr-lg-5">{realEstate.sections.about.commitment[0].title}</h1>
                        <p className="float-right ps-4">
                            CONTAMOS CON UN EQUIPO DE EXPERTOS
                            <br className="d-none d-lg-block" />
                            QUE SABEN LO QUE HACEN
                        </p>
                    </div>
                </div>
            </div>
            <div className="container-fluid half-fluid-about">
                <div className="row">
                    <div data-aos="fade-right" className="col-lg-12 col-11 offset-1 offset-lg-0 d-lg-flex align-items-center px-0 mb-lg-0">
                        <p className="d-none d-lg-block pe-4">
                            Últimas tecnologías para <br className="d-none d-lg-block" />
                            la comercialización <br className="d-none d-lg-block" /> 
                            de propiedades.
                        </p>
                        <h1 className="justify-content-left">{realEstate.sections.about.commitment[1].title}</h1>
                        <p className="d-block d-lg-none pe-4">
                            Últimas tecnologías para <br className="d-none d-lg-block" />
                            la comercialización <br className="d-none d-lg-block" /> 
                            de propiedades.
                        </p>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div data-aos="fade-left" className="col-12 d-flex align-items-lg-center px-lg-0 px-1 p mb-lg-3">
                        <h1 className="mr-lg-5">{realEstate.sections.about.commitment[2].title}</h1>
                        <p className="mb-0 ps-4">
                            11 años de experiencia en el
                            <br className="d-none d-lg-block" />
                             mercado inmobiliario.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Commitment;