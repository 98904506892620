import React, {useEffect} from "react";
import Breadcrumbs from "../breadcrumbs";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { graphql, useStaticQuery } from 'gatsby';
import { getBrText } from "../../helpers/helper.rendering";
import imageAbout from '../../images/2.jpg'

const Main = () => {

    const { realEstate } = useStaticQuery(graphql`
    query {
        realEstate {
            name
            about_us
            sections{
                home{
                    about{
                        title
                    }
                }
                about{
                    image
                }
            }
        }
    }`)
    
    useEffect(() => {
        AOS.init({ duration : 2000});
    }, [])

    return (
        <section className="about-main banner-main">
            <div className="container-fluid">
                <div className="row content-main d-flex align-items-center">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <Breadcrumbs
                                    props={[
                                    { name: realEstate.name, route: "/", location: "" },
                                    { name: "Nosotros", route:   "", location: ""},
                                    ]}
                                />
                            </div>
                            <div className="col-lg-8 col-xl-9 order-2 order-lg-1">
                                <h1>
                                    SOMOS {realEstate.name}.<br />
                                </h1>
                                <h2>
                                    {getBrText(realEstate.about_us).map(element => (
                                        <>
                                            {element}
                                            <br className="d-none d-lg-block" />
                                        </>
                                    ))}
                                </h2>
                                {/* <a className="btn btn-primary goto_about">CONOCENOS</a> */}
                            </div>
                            <div className="col-lg-4 col-xl-3 px-lg-0 order-1 order-lg-2 text-end">
                                <p>{realEstate.sections.home.about.title}</p>
                            </div>
                            <div className="col-12 order-3">
                                {/* <img className="w-100" src={realEstate.sections.about.image} alt={realEstate?.name} /> */}
                                <img className="w-100" src={imageAbout} alt="nosotros" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
} 

export default Main;