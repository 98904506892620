import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

//Components
import Main from '../components/Nosotros/main'
import History from '../components/Nosotros/history'
import Commitment from '../components/Nosotros/commitment'
import Team from '../components/Nosotros/team'

const IndexPage = () => (
  <Layout>
    <Seo title="Nosotros" description="Aquí debería ir la descripción de la sección de nosotros" />
    <Main />
    <History />
    <Commitment />
    <Team />
  </Layout>
)

export default IndexPage
